<template>
  <div justify="center">
    <v-icon small @click="openForm">
      {{ icons.mdiEye }}
    </v-icon>

    <v-dialog v-model="dialog" class="previewDialog" width="80%" :height="dialogHeight" scrollable persistent>
      <v-card tile>
        <overlays :is-absolute="true" :is-show="absoluteOverlayStatus"></overlays>
        <v-toolbar class="d-flex justify-end" width="100%" height="36px" color="#FFFDE7">
          <span class="mr-auto">プレービュー</span>
          <!-- <v-btn
            icon
            @click="showImg"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn> -->
          <v-btn icon @click="closeForm">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card class="overflow-y-auto px-5" height="100%" tile flat>
          <template v-if="apierror.status == 'error'">
            <div v-for="msg of apierror.messages" :key="msg">
              <v-row class="ml-6 mb-3 ma-3">
                <span style="color: red">* {{ msg }} </span>
              </v-row>
            </div>
          </template>
          <v-container class="pa-5">
            <v-row no-gutters>
              <v-col class="pa-1" cols="12" md="6">
                <img id="mainImg" ref="mainImg" :class="mainImgClass" :src="mainImg" @load="loadImg" />
              </v-col>
              <v-col class="d-flex align-start flex-column pa-1" cols="12" md="6">
                <span id="titleArea" :class="`${titleAreaClass} mb-3`">{{ item.item_name }}</span>
                <div
                  :style="`height:${overviewHeight}px !important;text-align:center;width:${overviewMaxWidth}px`"
                  class="itemOverview"
                  v-html="item.item_overview"
                ></div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="pa-1" cols="12">
                <v-card class="mt-10" flat tile>
                  <div :style="`font-size:${descFontSize}%;`" class="desc" v-html="item.item_desc"></div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mdiEye, mdiClose } from '@mdi/js'
import { mapMutations, mapActions, mapState } from 'vuex'
import Overlays from '@/components/Overlays.vue'

export default {
  components: {
    Overlays,
  },
  props: ['id'],
  data: () => ({
    imgHeight: '',
    overviewHeight: '',
    overviewMaxWidth: '',
    dialog: false,
    images: [],
    imgExist: false,
    mainImg: '',
    mainImgClass: '',
    titleAreaClass: '',
    titleFontSize: '',
    icons: {
      mdiEye,
      mdiClose,
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    item: {
      description: {
        item_desc: '',
      },
      overview: {
        item_overview: '',
      },
    },
  }),

  computed: {
    ...mapState('app', ['absoluteOverlayStatus']),
    ...mapState('itemStore', ['selectedItem']),
    descFontSize() {
      const device = this.$vuetify.breakpoint.name
      if (device === 'xs') return 75
      if (device === 'sm') return 100
      if (device === 'md') return 125
      if (device === 'lg') return 125

      return 125
    },
    dialogHeight() {
      return document.documentElement.clientHeight * 0.9
    },
    Itemdescription() {
      let description = ''
      if (!this.item.description || !this.item.description.item_desc) {
        description = ''
      }
      description = this.item.description.item_desc

      return description
    },
    ItemOverview() {
      let overview = ''
      if (!this.item.overview || !this.item.overview.item_overview) {
        overview = ''
      }
      overview = this.item.overview.item_overview

      return overview
    },
  },

  watch: {},

  created() {},
  mounted() {
    window.addEventListener('resize', this.resizeImg)
  },

  destroyed() {
    window.removeEventListener('resize', this.resizeImg)
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('itemStore', ['loadItem']),
    openForm() {
      this.mainImgClass = `mainImg${this.id}`
      this.titleAreaClass = `titleArea${this.id}`
      this.loadData()
    },
    closeForm() {
      this.dialog = false
      this.clearData()
      this.$emit('refresh')
    },
    clearData() {
      this.imgExist = false
      this.mainImg = ''
      this.item = {
        description: {
          item_desc: '',
        },
        overview: {
          item_overview: '',
        },
      }
    },
    handleSelectedImg(imgUrl) {
      this.mainImg = imgUrl
    },
    lazyload() {
      const that = this
      setTimeout(() => that.resizeImg(), 5000)
    },
    resizeImg() {
      if (this.imgExist) {
        if (this.$vuetify.breakpoint.name === 'xs') this.titleFontSize = 125
        if (this.$vuetify.breakpoint.name === 'sm') {
          this.titleFontSize = 150
        } else {
          this.titleFontSize = 175
        }
        document.querySelector(`.${this.titleAreaClass}`).style = `font-size:${this.titleFontSize}%;`
        const titleHeight = document.querySelector(`.${this.titleAreaClass}`).offsetHeight
        this.imgHeight = document.querySelector(`.${this.mainImgClass}`).offsetHeight
        const imgWidth = document.querySelector(`.${this.mainImgClass}`).offsetWidth
        this.overviewHeight = this.imgHeight - titleHeight - 12
        this.overviewMaxWidth = imgWidth
        console.log('resizeImg', this.$refs.mainImg.offsetHeight, this.imgHeight, titleHeight, this.overviewHeight)
      }
    },
    loadImg() {
      this.imgExist = true

      // this.lazyload()

      this.resizeImg()
    },
    showImg() {
      console.log('img', this.$refs.mainImg.offsetHeight)
    },
    loadData() {
      this.setOverlayStatus(true)
      this.loadItem(this.id)
        .then(() => {
          this.item = {
            ...this.selectedItem,
          }
          console.log('selectedItem', this.id, this.selectedItem)
          if (this.item.images) {
            this.images = this.item.images
              .replaceAll('"', '')
              .split(',')
              .map(i => `${this.item.imgUrl_prefix}/${i}`)
            this.handleSelectedImg(this.images[0])
          }

          this.setOverlayStatus(false)
          this.dialog = true
        })
        .catch(error => {
          console.log('error', error)
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
          this.setOverlayStatus(false)
          this.dialog = true
        })
    },
  },
}
</script>

<style scoped>
#mainImg {
  width: 100%;
  object-fit: contain;
}
#titleArea {
  /* font-size: 28px; */
  font-weight: 700;
  font-family: 'Noto Sans', CJK JP;
}
.desc {
  /* font-size: 20px; */
  font-family: 'Noto Sans', CJK JP;
  font-weight: 500;
  line-height: 45px;
}
.itemOverview >>> p {
  height: 100%;
  margin-bottom: 0;
  text-align: center;
}
.itemOverview >>> img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.v-toolbar >>> .v-toolbar__content {
  width: 100% !important;
}
</style>
