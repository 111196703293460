<template>
  <div id="products-list">
    <v-card>
      <v-card-title>単品（一覧）</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="item_code"
            :items="itemCodes"
            placeholder="商品コード"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="item_name"
            :items="itemNames"
            placeholder="商品名"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="search"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'items-create', params: { query: this.$route.query } }"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新規</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-3"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        ref="datatable"
        :headers="headers"
        :items="itemList"
        sort-by
        class="class-with-table"
        :search="search"
      >
        <template v-slot:[`item.item_name`]="{ item }">
          <label>
            {{ item.item_name }}
          </label>
        </template>
        <template v-slot:[`item.image`]="{ item }">
          <v-img
            :src="item.image ? item.image : require('@/assets/images/noimage.jpg')"
            max-height="50"
            max-width="50"
            style="margin: 0 auto 0 0"
          >
          </v-img>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <ItemPreView
              :id="item.id"
              class="py-2 pl-1"
              @refresh="loadData"
            >
            </ItemPreView>
            <div class="py-2 pl-1">
              <v-icon
                small
                @click="editItem(item)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </div>
            <DeleteItem
              :id="item.id"
              class="py-2 pl-1"
              @refresh="loadData"
            ></DeleteItem>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'
import {
  mdiPencil,
  mdiPlus,
  mdiExportVariant,
} from '@mdi/js'
import DeleteItem from '@/views/bimi/product/item/ItemsDelete.vue'
import ItemPreView from '@/views/bimi/product/item/ItemPreView.vue'

export default {
  components: {
    ItemPreView,
    DeleteItem,
  },
  data: () => ({
    search: '',
    item_code: '',
    item_name: '',
    icons: {
      mdiPencil,
      mdiPlus,
      mdiExportVariant,
    },
    headers: [
      {
        text: 'イメージ',
        value: 'image',
        align: 'left',
        width: '10%',
        sortable: false,
        fixed: true,
      },
      {
        text: 'ID',
        value: 'id',
        width: '10%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '商品コード',
        value: 'item_code',
        width: '20%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '商品名',
        align: 'left',
        sortable: true,
        value: 'item_name',
        width: '53%',
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        width: '7%',
        align: 'right',
        fixed: true,
      },
    ],
  }),
  computed: {
    ...mapState('itemStore', ['itemList', 'itemCodes', 'itemNames']),
  },
  watch: {
    item_name(newValue) {
      this.changeRouteQuery('item_name', newValue)
      this.loadData()
    },
    item_code(newValue) {
      this.changeRouteQuery('item_code', newValue)
      this.loadData()
    },
  },
  created() {
    this.loadQuery()
    this.changeRouteQuery()
    this.loadData()
  },
  destroyed() {
    this.clearItemList()
    this.clearItemCodes()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('itemStore', ['loadItemList', 'loadItemCodes']),
    ...mapMutations('itemStore', ['clearItemList', 'clearItemCodes']),

    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadItemCodes(),
        this.loadItemList(this.$route.query),
      ]).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({
        path: this.$route.path, query,
      })
    },

    loadQuery() {
      this.item_name = this.$route.query.item_name ?? ''
      // eslint-disable-next-line radix
      this.item_code = this.$route.query.item_code ? parseInt(this.$route.query.item_code) : ''
    },
    editItem(item) {
      this.$router.push({
        name: 'items-edit',
        params: {
          id: item.id, query: this.$route.query,
        },
      })
    },
  },
}
</script>
<style>
.class-with-table{
  font-size:30px;
}
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
